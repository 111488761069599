<template>
	<v-sheet v-if="worker_id != null && workerData != null">
		<v-toolbar flat>
			<v-toolbar-title>{{workerData.name}}信息</v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn
				color="primary"
				text
				@click="$router.go(-1)"
				elevation="0">
				返回上页
			</v-btn>
		</v-toolbar>
		<v-divider></v-divider>
		<v-container>
			<div class="d-flex align-center">
				<div class="subtitle-2 mr-2" style="width:140px">
				<v-chip
					:color="workerData.archived ? 'error' :'success'"
				>
					{{workerStatus}}
				</v-chip>
				</div>
				<div class="d-flex flex-wrap justify-end align-center flex-grow-1" v-if="user && user.role === 'admin'">
					<v-btn small text color="info" @click="dialogEditWorker = true"><v-icon left>mdi-pencil</v-icon>编辑工人信息</v-btn>
				</div>
			</div>
		</v-container>
		<v-divider></v-divider>

		<v-container v-if="workerData" class="py-0">
			<v-row no-gutters class="px-3">
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">工人姓名:</div>
						<div class="body-2">{{workerData.name}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">出生日期:</div>
						<div class="body-2">{{workerData.date_of_birth}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">联系电话:</div>
						<div class="body-2">{{workerData.phone}}</div>
					</div>
				</v-col>

				<v-col cols="12" sm="8">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">家庭住址:</div>
						<div class="body-2">{{workerData.address}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">电子邮件:</div>
						<div class="body-2">{{workerData.email}}</div>
					</div>
				</v-col>
			</v-row>
			<v-divider class="mt-4"></v-divider>
			<v-row no-gutters class="px-3">
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">TFN(税号):</div>
						<div class="body-2">{{workerData.tfn}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">Work Safe:</div>
						<div class="body-2">{{workerData.work_safe}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">Cbus:</div>
						<div class="body-2">{{workerData.cbus}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">Incolink:</div>
						<div class="body-2">{{workerData.incolink}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">Coinvest:</div>
						<div class="body-2">{{workerData.coinvest}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">Cfmeu:</div>
						<div class="body-2">{{workerData.cfmenu}}</div>
					</div>
				</v-col>
			</v-row>
			<v-divider class="mt-4"></v-divider>
			<v-row no-gutters class="px-3">
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">开始日期:</div>
						<div class="body-2">{{workerData.start_date}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">结束日期:</div>
						<div class="body-2">{{workerData.end_date}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="4">
					<div class="d-flex pa-1 align-center mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">创建人:</div>
						<div class="body-2">{{workerData.owner.name}}</div>
					</div>
				</v-col>
				<v-col cols="12" sm="12">
					<div class="d-flex pa-1 align-start mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">所属团队:</div>
						<div class="body-2" v-if="workerData.work_teams && workerData.work_teams.length">
							<template v-for="team in workerData.work_teams">
								<v-btn
									:key="team.id"
									elevation="0"
									small
									class="mr-2 mb-2"

									:to="{ name: 'viewTeam', params: {id:team.id}}"
								>
										{{team.name}}
										<v-icon small right>
											mdi-open-in-new
										</v-icon>
								</v-btn>
							</template>
						</div>
						<div class="body-2" v-else>
							工人不属于任何团队
						</div>
					</div>
				</v-col>
				<v-col cols="12" sm="12">
					<div class="d-flex pa-1 align-start mt-4">
						<div class="subtitle-2 mr-2" style="min-width:80px">注释:</div>
						<div class="body-2">{{workerData.notes}}</div>
					</div>
				</v-col>
			</v-row>
		</v-container>
		<v-divider class="mt-6"></v-divider>
		<!--- 编辑工人信息 ---->
		<v-dialog v-model="dialogEditWorker" 
			max-width="480">
				<worker-form 
					:workerData="workerData"
					@on-submit-success="onWorkFormSubmit"
				/>
		</v-dialog>
	</v-sheet>
</template>

<script>

import { mapState } from 'vuex';
import WorkerService from '../../services/Worker';
import WorkerForm from '../../components/Works/WokerFrom.vue';

export default {
	name: 'viewWorker',
	components: {
		WorkerForm
	},
	created(){
		this.worker_id = parseInt(this.$route.params.id);
		if(this.worker_id){
			this.getWorkerDetail(this.worker_id);
		}
	},
	data: () => ({
		worker_id: null,
		workerData: null,
		dialogEditWorker: false
	
	}),
	computed: {
		...mapState('user', ['user'],),
		workerStatus(){
			return this.workerData.archived ? '归档状态' : '激活状态';
		}
	},
	watch: {
	
	},
	methods: {
		async getWorkerDetail(worker_id){
			this.workerData = null;
			try {
				let response = await WorkerService.getWorker(worker_id);
				this.workerData = response.data.data;
			} catch(error) {
				console.log('There has been a problem with your api call: ', error);
			}
		},
		onWorkFormSubmit(){
			this.dialogEditWorker = false;
			this.getWorkerDetail(this.worker_id);
		}
	}
}
</script>
